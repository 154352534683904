<template>
  <div class="contacts-page view-page">
    <c-search searchTitle="客户姓名/电话/编号" placeholder="姓名/电话" @search="search"></c-search>
    <c-navigation @change="changeDepartment" title="部门" :list="$store.state.departmentListAll"></c-navigation>
    <c-table :data="contactsData">
      <el-table-column prop="username" label="姓名" width="150"> </el-table-column>
      <el-table-column prop="sex" label="性别" width="150"> </el-table-column>
      <el-table-column prop="mobile" label="电话" width="150"> </el-table-column>
      <el-table-column prop="section" label="部门" width="150"> </el-table-column>
      <el-table-column prop="row" label="角色" width="150"> </el-table-column>
      <el-table-column prop="entrytime" label="入职时间" width="150">
        <template #default="{ row }">
          <p v-if="row.entrytime">{{ row.entrytime.split(' ')[0] }}</p>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template #default="{ row }">
          <div :style="row.status | colorFilter">
            {{ row.status }}
          </div>
        </template>
      </el-table-column>
    </c-table>
    <!-- 分页 -->
    <div class="page">
      <c-pagination @change="changePage" :pageSize="contactsParam.limit" :total="contactsTotal"></c-pagination>
    </div>
  </div>
</template>

<script>
import * as contactsApi from '@/api/contacts.js'
import { getSector } from '@/api/common.js'

export default {
  name: 'Contacts',
  data() {
    return {
      // 列表信息
      contactsData: [],

      contactsParam: {
        usmob: '',
        section_id: '0',
        status: '',
        page: 1,
        limit: 7
      },
      contactsTotal: 0
    }
  },
  filters: {
    colorFilter(value) {
      if (value == '在职') {
        return 'color:#333333'
      }
      return 'color:#F22323'
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const id = this.$store.state.departmentListAll[0].id
      this.contactsParam.section_id = id
      this.getContacts()
    },
    changeDepartment(item) {
      this.contactsParam.section_id = item.id
      this.contactsParam.page = 1
      this.getContacts()
    },

    /**
     * 搜索关键字
     */
    search(v) {
      this.contactsParam.page = 1
      this.contactsParam.usmob = v.keyWord
      this.getContacts()
    },
    /**
     * @description: 获取通讯录
     */
    getContacts() {
      contactsApi.getContacts(this.contactsParam).then(res => {
        this.contactsData = res.data.data
        this.contactsTotal = res.data.total
      })
    },
    /**
     * 跳转页数
     */
    changePage(i) {
      this.contactsParam.page = i
      this.getContacts()
    }
  }
}
</script>

<style lang="scss" scoped>
.contacts-page {
  background-color: #fff;
}
.list {
  padding: 0 1.98rem;
  .list1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #ebf0f0;
    height: 4.5rem;

    .department {
      margin-right: 7.97rem;
    }
  }
  // .department {
  //   display: flex;
  //   align-items: center;
  //   justify-content: flex-start;
  //   border-bottom: 1px solid #ebf0f0;
  //   height: 4.11rem;
  // }
  .list-item {
    .table {
      border: 1px solid #e4e4e4;
    }
  }
}
:deep() .el-table__cell {
  color: #333333;
  font-size: 0.83rem;
  text-align: center;
}
:deep() .el-table__body tr:hover > td.el-table__cell {
  background-color: #eaf0f0;
}
.textColor {
  color: #f22323;
}
</style>
